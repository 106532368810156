import { IUser } from '@/interfaces/user.interface';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import categories from '@/api/categories';
import { Categories } from '@/interfaces/categories.interface';
import products from '@/api/products';
import { Product } from '@/interfaces/product.interface';


interface ProductsState {
    products: any[] | null; //<Categories[]>;
    productsBanner: any[] | null;
    productsByParam: any[] | null;
    // productsByBrand: any[] | null;
    getProductDetail: (categoryId: string, productId: string) => Promise<Product>;
    getProductsBanner: (searchProduct: string) => Promise<any[]>;
    getProductsByParam: (page: number, limit: number, categoryId?: string, brandId?: string, search?: string) => Promise<any[]>;
    // getProductsByBrand: (brandId: string, page: number, limit: number) => Promise<any[]>;
    productDetail: any[] | [];//Categories[] | [];
    getRelationatedProductsById: (productId: string) => Promise<Product[]>;
}

const useProductsStore = create<ProductsState>()(
    persist(
        (set) => ({
            products: [],
            productDetail: [],
            productsBanner: [],
            productsByParam: [],
            relatedProducts: [],
            // productsByBrand: [],
            getProductDetail: async (categoryId: string, productId: string) => {
                // list all categories
                const response = await products.listProductsById(categoryId, productId);

                // console.log('productDetail', response);

                set((state) => ({
                    ...state,
                    productDetail: response.data,
                }));
                return response.data;
            },
            getProductsBanner: async (searchProduct: string) => {
                // get products bby search param
                const response = await products.listProductsByCategory(1, 10, undefined, undefined, searchProduct);

                console.log('response', response);

                set((state) => ({
                    ...state,
                    productBanner: response.data,
                }));
                return response.data;
            },
            getProductsByParam: async (page: number, limit: number, categoryId?: string, brandId?: string, search?: string) => {
                // get products by search param
                const response = await products.listProductsByCategory(1, 10, categoryId, brandId, search);

                console.log('response', response);

                set((state) => ({
                    ...state,
                    productsBySearch: response.data,
                }));
                return response.data;
            },
            getRelationatedProductsById: async (productId: string) => {
                // get products by search param
                const response = await products.getRelationatedProductsById(productId);

                console.log('response', response);

                set((state) => ({
                    ...state,
                    relatedProducts: response.data,
                }));
                return response.data;

            }
            // getProductsByBrand: async (brandId: string, page: number, limit: number) => {
            //     // get products bby search param
            //     const response = await products.listProductsByCategory(page, limit, undefined, brandId, undefined);

            //     console.log('response', response);

            //     set((state) => ({
            //         ...state,
            //         productsByBrand: response.data,
            //     }));
            //     return response.data;
            // },
        }),
        {
            name: 'products',
        }
    )
);

export default useProductsStore;