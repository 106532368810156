import { AxiosResponse } from 'axios';
import client from './client';

import { setCookie } from 'cookies-next';
import { Categories } from '@/interfaces/categories.interface';
import { Product } from '@/interfaces/product.interface';

const ENDPOINT = "products";


const listProductsByCategory = (
    page: number,
    limit: number,
    categoryId?: string,
    brand?: string,
    searcher?: string
): Promise<AxiosResponse<Categories[]>> => {
    const queryParams: any = {};

    switch (true) {
        case true:
            addQueryParam(queryParams, 'categoryId', categoryId);
            addQueryParam(queryParams, 'brand', brand);
            addQueryParam(queryParams, 'searcher', searcher);
            break;
        default:
            break;
    }

    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/list?${queryString}&page=${page}&limit=${limit}`;

    console.log('url', url);

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

const listProductsById = (categoryId: string, productId: string): Promise<AxiosResponse<any>> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/get-product-by-id?categoryId=${categoryId}&productId=${productId}`;

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

const getRelationatedProductsById = (productId: string): Promise<AxiosResponse<Product[]>> => {
    const url = `${client.BASE_URL}/api/client/products/get-relationated-products?productId=${productId}`;

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};


const addQueryParam = (queryParams: any, key: string, value: any) => {
    if (value !== undefined && value !== null && value !== 'null') {
        queryParams[key] = value;
    }
};

export default {
    getRelationatedProductsById,
    listProductsByCategory,
    listProductsById,

};

// https://saas-ecommerce-api-development.up.railway.app/api/products/get-by-category?categoryId=1&limit=10&page=1