/* eslint-disable no-undef */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getCookie, setCookie } from "cookies-next";
const BASE_URL = 'https://apins.usacomputer.com.mx';//process.env.BASE_URL;
// const BASE_URL = 'https://apins-dev.usacomputer.com.mx';//process.env.BASE_URL;
// const BASE_URL = process.env.BASE_URL;
// const BASE_URL = "http://localhost:4000";

const TIMEOUT = 50000;

const client: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    withCredentials: false,
    responseType: "json",
    headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

const UNAUTHORIZED = 401;

client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
            localStorage.removeItem("token");
            // history.push("/");
        }
        return Promise.reject(error);
    }
);

const get = (...args: Parameters<AxiosInstance["get"]>): Promise<AxiosResponse> => {
    getToken();
    return new Promise((resolve, reject) => {
        client
            .get(...args)
            .then((response) => resolve(response))
            .catch(reject);
    });
};

const post = (...args: Parameters<AxiosInstance["post"]>): Promise<AxiosResponse> => {
    getToken();
    return new Promise((resolve, reject) => {
        client
            .post(...args)
            .then((response) => resolve(response))
            .catch(reject);
    });
};

const patch = (...args: Parameters<AxiosInstance["patch"]>): Promise<AxiosResponse> => {
    getToken();
    return new Promise((resolve, reject) => {
        client
            .patch(...args)
            .then((response) => resolve(response))
            .catch(reject);
    });
};

const remove = (url: string, data?: any): Promise<AxiosResponse> => {
    getToken();
    return new Promise((resolve, reject) => {
        client
            .delete(url, {
                headers: {
                    Authorization: client.defaults.headers.common["Authorization"],
                },
                data,
            })
            .then((response) => resolve(response))
            .catch(reject);
    });
};

const getToken = (): void => {
    // const token = localStorage.getItem("token");
    const token = getCookie('token');

    console.log('token', token);
    if (token) {
        client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};

const addToken = (token: string): void => {
    // client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const removeToken = (): void => {
    // delete client.defaults.headers.common["Authorization"];
};

const storeToken = (token: string): void => {
    setCookie('token', token);
    // localStorage.setItem("token", token);
    // addToken(token);
};

const clearToken = (): void => {
    // removeToken();
    // localStorage.removeItem("token");
};

export default {
    BASE_URL,
    get,
    post,
    patch,
    remove,
    storeToken,
    clearToken,
};

