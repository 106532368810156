// import React, { FC } from "react";
// import { Transition } from "@/app/headlessui";
// import Prices from "@/components/Prices";
// import { PRODUCTS } from "@/data/data";
// import Image, { StaticImageData } from "next/image";

// interface Props {
//   show: boolean;
//   name: string;
//   price: number;
//   productImage: string | StaticImageData;
//   variantActive: number;
//   sizeSelected?: string;
//   quantitySelected: number;
// }

// const NotifyAddTocart: FC<Props> = ({
//   show,
//   name,
//   price,
//   productImage,
//   variantActive,
//   quantitySelected,
//   sizeSelected,
// }) => {
//   // const { name, price, variants } = PRODUCTS[0];

//   console.log('price', price);

//   const renderProductCartOnNotify = () => {
//     return (
//       <div className="flex ">
//         <div className="h-24 w-20 relative flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
//           <Image
//             src={productImage}
//             alt={name}
//             fill
//             sizes="100px"
//             className="h-full w-full object-contain object-center"
//           />
//         </div>

//         <div className="ml-4 flex flex-1 flex-col">
//           <div>
//             <div className="flex justify-between ">
//               <div>
//                 <h3 className="text-base font-medium ">{name}</h3>
//                 <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
//                   {/* <span>
//                     {variants ? variants[variantActive].name : `Natural`}
//                   </span> */}
//                   {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> */}
//                   {/* <span>{sizeSelected || "XL"}</span> */}
//                 </p>
//               </div>
//               <Prices price={price} className="mt-0.5" />
//             </div>
//           </div>
//           <div className="flex flex-1 items-end justify-between text-sm">
//             <p className="text-gray-500 dark:text-slate-400">{`Qty ${quantitySelected}`}</p>

//             <div className="flex">
//               <button
//                 type="button"
//                 className="font-medium text-primary-6000 dark:text-primary-500 "
//               >
//                 Ver Carrito
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <Transition
//       appear
//       as={"div"}
//       show={show}
//       className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
//       enter="transition-all duration-150"
//       enterFrom="opacity-0 translate-x-20"
//       enterTo="opacity-100 translate-x-0"
//       leave="transition-all duration-150"
//       leaveFrom="opacity-100 translate-x-0"
//       leaveTo="opacity-0 translate-x-20"
//     >
//       <p className="block text-base font-semibold leading-none">
//         Añadido al carrito!
//       </p>
//       <hr className=" border-slate-200 dark:border-slate-700 my-4" />
//       {renderProductCartOnNotify()}
//     </Transition>
//   );
// };

// export default NotifyAddTocart;

import React, { FC } from "react";
import { Transition } from "@/app/headlessui";
import Prices from "@/components/Prices";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";

interface Props {
  show: boolean;
  name: string;
  price: number | string;
  productImage: string | StaticImageData;
  variantActive: number;
  sizeSelected?: string;
  quantitySelected: number;
  onClose: () => void;
}

const NotifyAddTocart: FC<Props> = ({
  show,
  name,
  price,
  productImage,
  variantActive,
  quantitySelected,
  sizeSelected,
  onClose,
}) => {
  const renderProductCartOnNotify = () => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 relative flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <Image
            src={productImage}
            alt={name}
            fill
            sizes="100px"
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  {/* <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span> */}
                  {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> */}
                  {/* <span>{sizeSelected || "XL"}</span> */}
                </p>
              </div>
              <Prices price={price} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">{`Cantidad ${quantitySelected}`}</p>

            <Link href={'/cart'} className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                Ver Carrito
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition
      appear
      as={"div"}
      show={show}
      className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200 relative"
      enter="transition-all duration-150"
      enterFrom="opacity-0 translate-x-20"
      enterTo="opacity-100 translate-x-0"
      leave="transition-all duration-150"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-20"
    >
      {/* Botón de cierre */}
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <p className="block text-base font-semibold leading-none">
        Añadido al carrito!
      </p>
      <hr className=" border-slate-200 dark:border-slate-700 my-4" />
      {renderProductCartOnNotify()}
    </Transition>
  );
};

export default NotifyAddTocart;